<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <g fill-rule="nonzero">
            <path
                d="M54.99 35.404a2 2 0 1 1-2.83-2.828l2.865-2.865c4.491-4.49 11.773-4.49 16.264 0 4.49 4.491 4.49 11.773 0 16.264L59.975 57.289c-4.491 4.49-11.773 4.49-16.264 0a2 2 0 1 1 2.829-2.829 7.5 7.5 0 0 0 10.606 0L68.46 43.146A7.5 7.5 0 0 0 57.854 32.54l-2.865 2.864Z" />
            <path
                d="M46.01 65.596a2 2 0 1 1 2.83 2.828l-2.865 2.865c-4.491 4.49-11.773 4.49-16.264 0-4.49-4.491-4.49-11.773 0-16.264l11.314-11.314c4.491-4.49 11.773-4.49 16.264 0a2 2 0 1 1-2.829 2.829 7.5 7.5 0 0 0-10.606 0L32.54 57.854A7.5 7.5 0 0 0 43.146 68.46l2.865-2.864Z" />
        </g>
    </svg>
</template>

<script type="application/javascript">
    export default {
        props: {},
        data: () => ({}),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        computed: {},
        watch: {},
        components: {},
        name: "IconLink"
    };
</script>

<style lang="scss" scoped>

</style>
