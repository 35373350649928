<template>
    <section v-if="posts.length"
             :id="`category-${index}`"
             class="light-grey-bg category-item">
        <div class="wrapper">
            <h2 class="xlg-title"
                v-html="categoryTitle" />
            <div class="questions">
                <span class="anchor"
                      :id="catBlockId" />
                <FAQItem v-for="({answer, id, question, tocopylink}, index) in posts"
                         :key="index"
                         :answer="answer"
                         :id="`${catBlockId}-${id}`"
                         :index="parseInt(index)"
                         :question="question"
                         :share-url="tocopylink" />
                <DotLoading :loading="loading" />
            </div>

            <div v-if="maxPages > 1 && maxPages !== page"
                 class="btn-block center-aligned">
                <button v-show="!loading"
                        class="btn lg brown"
                        @click.prevent="fetchMore"
                        v-html="loadMoreText" />
            </div>

            <a :href="`#${catBlockId}`" class="back-to-top">
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 26" preserveAspectRatio="none">
                        <path d="M.136 10.834h30.702l-7.757-7.778L26.136.001l13 13-13 13-3.055-3.055 7.757-7.778H.136z" fill-rule="evenodd"></path>
                    </svg>
                </span>

                <span class="text">
									Back to top of <i v-html="categoryTitle" />
								</span>
            </a>
        </div>
    </section>
</template>

<script type="application/javascript">
    /**
     * NPM Packages
     */
    import axios from "axios";
    
    /**
     * Vue Scripts
     */
    import mixins from "../../Util/mixins.js";
    
    /**
     * Vue Components
     */
    import FAQItem from "./FAQItem.vue";
    import DotLoading from "../Blocks/DotLoading.vue";
    
    export default {
        props: {
            categoryId: {
                type: Number,
                default: 0
            },
            categoryTitle: {
                type: String,
                default: "",
            },
            index: {
                type: Number,
                default: 0,
            },
            resetsSearchTrigger: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            backToTop: "",
            blockId: "",
            catBlockId: "",
            loading: false,
            maxPages: 1,
            page: 1,
            posts: [],
            sharePrefix: ARCHIVE.permalink,
        }),
        beforeCreate() {},
        created() {
        },
        beforeMount() {
            this.backToTop = `Back to top of ${this.categoryTitle}`;
            this.catBlockId = this.slugText(this.categoryTitle) + this.index;
            this.getFaqItems();
        },
        mounted() {},
        methods: {
            getFaqItems(reset = false) {
                if (reset) this.page = 1;
                const params = {ppp: this.ppp, category: this.categoryId, page: this.page};
                if (this.searchText) params.s = this.searchText;
                this.loading = true;
                
                axios
                    .get(this.api, {params})
                    .then(({data, status}) => {
                        if (status === 200) {
                            const posts = Object.values(data.posts);
                            if (this.page > 1) {
                                this.posts.push(...posts);
                            }
                            else {
                                this.posts = posts;
                                this.maxPages = data.maxPages;
                            }
                        }
                    })
                    .catch(err => console.error(err))
                    .finally(() => this.loading = false);
            },
            fetchMore() {
                this.page = this.page + 1;
                this.getFaqItems();
            },
        },
        computed: {
            api() {return this.$store.state.apiUrl;},
            loadMoreText() {return this.$store.state.loadMore;},
            ppp() {return this.$store.state.ppp;},
            searchText() {return this.$store.state.searchText;},
            searchTriggered() {return this.$store.state.searchTriggered;},
        },
        watch: {
            searchTriggered(triggered) {
                if (triggered) this.getFaqItems(true);
                if (this.resetsSearchTrigger) this.commitState({searchTriggered: false});
            }
        },
        mixins: [mixins],
        components: {DotLoading, FAQItem},
        name: "FAQCategory"
    };
</script>

<style lang="scss" scoped>

</style>
