var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events"},[_c('div',{staticClass:"wrapper"},[(_vm.title)?_c('h2',{staticClass:"xl-title center-aligned",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('Loading',{attrs:{"is-loading":!!_vm.loading,"color":"#3762ae"}}),_vm._v(" "),_c('div',{staticClass:"events-grid"},[_vm._l((_vm.posts),function(ref){
var date = ref.date;
var id = ref.id;
var image = ref.image;
var permalink = ref.permalink;
var tags = ref.tags;
var title = ref.title;
return (!_vm.loading && _vm.posts.length)?_c('Post',{key:id,attrs:{"date":date,"image":image,"permalink":permalink,"tag":tags,"title":title}}):_vm._e()}),_vm._v(" "),(!_vm.loading && !_vm.posts.length)?_c('NoResults',{attrs:{"no-results-text":_vm.noResults}}):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }