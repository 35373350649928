<template>
    <div :class="`event-indiv ${image ? 'with-img' : ''}`">
        <a :href="permalink">
            <div v-if="this.objNotEmpty(image)"
                 class="image">
                <PostImage :image="image" />
            </div>
            <div class="info light-text">
                <h3 class="xs-title"
                    v-html="title" />
            </div>
        </a>
        <div class="info-bottom light-text">
            <div class="tag"
                 v-html="tag" />
            <div class="date"
                 v-html="date" />
        </div>
    </div>
</template>

<script type="application/javascript">
    import PostImage from "./PostImage.vue";
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            date: String,
            image: {
                type: [Array, Object],
                default: null,
            },
            permalink: String,
            tag: String,
            title: String,
        },
        data: () => ({}),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        computed: {},
        watch: {},
        components: {PostImage},
        mixins: [mixins],
        name: "Post"
    };
</script>

<style lang="scss" scoped>

</style>
