<template>
    <div :id="id"
         :class="`question-indiv white-bg${isOpen ? ' open' : ''}`"
         ref="faqItem">
        <button class="header"
                @click="toggleItem">
            <span class="xs-title"
                  v-html="question" />
            <span class="question-toggle" />
        </button>
        <div class="content">
            <div class="text">
                <div class="copy"
                     v-html="answer" />
            </div>
            <div class="share">
                <button class="copy-target"
                        ref="copyTarget"
                        @click="copyLink"
                        @mouseenter="toggleClickToCopy(true)"
                        @mouseleave="toggleClickToCopy(false)"
                        :data-clipboard-text="`${shareUrl}`">
                    <span class="icon">
                        <IconLink />
                    </span>
                    <span class="copy-bold"
                          v-html="`Share Question`" />
                </button>
                <div class="copy-link"
                     ref="copyHoverText">
                    <span class="caption"
                          v-html="`Click to copy link to clipboard`" />
                </div>
                <div class="link-copied"
                     ref="copiedHoverText">
                    <span class="caption"
                          v-html="`Link has been copied!`" />
                </div>
            </div>
        </div>
    </div>
</template>

<script type="application/javascript">
    /**
     * JS Plugins
     */
    
    /**
     * Vue Scripts
     */
    import mixins from "../../Util/mixins.js";
    
    /**
     * Vue Components
     */
    import IconLink from "../Icons/IconLink.vue";
    
    export default {
        props: {
            answer: {
                type: String,
                default: "",
            },
            id: {
                type: String,
                default: "",
            },
            index: {
                type: Number,
                default: false,
            },
            question: {
                type: String,
                default: "",
            },
            tocopylink: {
                type: String,
                default: "",
            },
            shareUrl: {
                type: String,
                default: "",
            }
        },
        data: () => ({
            isOpen: false,
        }),
        beforeCreate() {},
        created() {
            this.isOpen = this.index === 0;
        },
        beforeMount() {},
        mounted() {},
        methods: {
            /**
             * This event handles the FAQ items active state
             */
            toggleItem() {
                this.isOpen = !this.isOpen;
            },
            /**
             * This event handles the click to copy functionality
             */
            copyLink() {
                this.toggleClickToCopy(false);
                this.copyText(this.$refs.copyTarget.dataset.clipboardText)
                    .then(
                        () => this.copiedEvents(this.$refs.copiedHoverText),
                        err => console.error(err)
                    );
            },
            /**
             * This event handles the state of the click to copy text
             * @param {boolean} visible
             */
            toggleClickToCopy(visible) {
                if (visible) this.$refs.copyHoverText.classList.add("visible");
                else this.$refs.copyHoverText.classList.remove("visible");
            }
        },
        computed: {},
        watch: {},
        mixins: [mixins],
        components: {IconLink},
        name: "FAQItem"
    };
</script>

<style lang="scss" scoped>

</style>
