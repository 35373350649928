<template>
    <section id="filter-search"
             class="faq-container">
        <FilterBar :apply-filter-text="filterBar.apply_filter_text"
                   :clear-filter-text="filterBar.clear_filter_text"
                   :clear-search-text="filterBar.clear_search_text"
                   :filter-by-label="filterBar.filter_by_label"
                   :filters="filters"
                   :search-label="filterBar.search_label"
                   :search-placeholder="filterBar.search_placeholer" />
        
        <FAQCategory v-if="categories.length"
                     v-for="({ID, name, slug}, index) in categories"
                     :key="index"
                     :category-id="ID"
                     :category-title="name"
                     :index="index"
                     :resets-search-trigger="(index + 1) === categories.length" />

        <div class="curved-edge">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.6 122.2" style="enable-background:new 0 0 486.6 122.2" xml:space="preserve" preserveAspectRatio="none">
                <path d="M0 0v3.3h14.3c28.4 0 56.8-.1 85.2.1 15.1.1 30.2 1.2 45.3 2.3 12.7.9 25.4 1.9 38 3.2 17 1.8 34 4 50.9 6.7 29.9 4.8 59.5 11.1 88.7 19.6 30.4 8.8 59.8 20 88 34.4 23.5 12.1 45.7 26.4 65.9 43.5 3.4 2.9 6.7 5.9 10.3 9.1V0H0z"></path>
            </svg>
        </div>
    </section>
</template>

<script type="application/javascript">
    /**
     * Vue Scripts
     */
    import mixins from "../Util/mixins.js";
    
    /**
     * Vue Components
     */
    import FAQCategory from "./FAQ/FAQCategory.vue";
    import FilterBar from "./Archive/FilterBarFaq.vue";
    
    export default {
        props: {},
        data: () => ({
            filterBar: ARCHIVE.filter.filter_bar_labels || [],
            filters: [
                ARCHIVE.categories,
            ],
            postType: ARCHIVE.postType,
        }),
        beforeCreate() {},
        created() {
            const {apiUrl, categories, ppp, loadMore} = ARCHIVE;
            this.commitState({
                apiUrl,
                categories: categories.items,
                defaultCategories: categories.items,
                loadMore,
                ppp,
            });
            this.$store.commit("setUpQuery", {postType: this.postType});
        },
        beforeMount() {},
        mounted() {},
        methods: {
            //  getPagedItems() {
            //     this.$store.dispatch('getPagedItems', {
            //         api: this.apiUrl,
            //         paged: this.paged,
            //         query: this.query,
            //     });
            // }
        },
        computed: {
            categories() {return this.$store.state.categories;},
        },
        watch: {},
        mixins: [mixins],
        components: {FAQCategory, FilterBar},
        name: "FAQ"
    };
</script>

<style scoped>

</style>
